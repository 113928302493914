import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Domain, DomainPageItem} from '../interfaces/domain';

@Injectable()
export class DomainService extends ApiService<DomainPageItem, Domain> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected resourcePath(): string {
        return 'domain';
    }
}
