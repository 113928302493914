import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SpoofEntity, SpoofUserInfo, User, UserChangePassword, UserJob} from './user';
import {ApiService} from '../../../shared/service/api.service';
import {UserPageItem} from './user-page-item';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable()
export class UserService extends ApiService<UserPageItem, User> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected resourcePath(): string {
        return 'users';
    }

    public updatePassword(to: UserChangePassword): Observable<any> {
        return this.httpClient.put(`${environment.API_ROOT_PATH}user/password`, to);
    }

    public updateUser(to: User): Observable<any> {
        return this.httpClient.patch(`${environment.API_ROOT_PATH}user/roles`, to);
    }

    public updateJob(to: UserJob): Observable<any> {
        return this.httpClient.patch(`${this.getResourceUrl()}/job`, to);
    }

    public blockUser(id: number): Observable<any> {
        return this.httpClient.patch(`${this.getResourceUrl()}/block/${id}`, {});
    }

    public reset2faCode(id: number): Observable<any> {
        return this.httpClient.post(`${this.getResourceUrl()}/reset-totp/${id}`, {});
    }

    changeFinancePermissions(id: number): Observable<any> {
        return this.httpClient.patch(`${environment.API_ROOT_PATH}users/view_finances/${id}`, {});
    }

    manageSpoof(id: number): Observable<boolean> {
        return this.httpClient.patch<boolean>(`${this.getResourceUrl()}/spoof/${id}`, {});
    }

    spoof(spoofEntity: SpoofEntity): Observable<SpoofUserInfo> {
        return this.httpClient.post<SpoofUserInfo>(`${this.getResourceUrl()}/spoof/${spoofEntity.username}`, {});
    }

    unSpoof(): Observable<SpoofUserInfo> {
        return this.httpClient.post<SpoofUserInfo>(`${this.getResourceUrl()}/unspoof/`, {});
    }
}
