import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class VersionService {

    constructor(private httpClient: HttpClient) {
    }

    public getApplicationVersion(): Observable<string> {
        return this.httpClient.get<string>(`${environment.API_ROOT_PATH}version`, {});
    }
}
